import * as React from "react"
import { ChevronsUpDown, Plus } from "lucide-react"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar"
import { PropertyResponse } from "@/pages/property/types/response/property-response"

export function PropertySwitcher({
  properties,
}: {
  properties: PropertyResponse[]
}) {
  const { isMobile } = useSidebar()
  const [activeProperty, setActiveProperty] = React.useState<PropertyResponse | null>(null)
  React.useEffect(() => {
    if (properties.length > 0) {
      const activePropertyId = localStorage.getItem("property.active")
      const property = properties.find((property) => property.id === activePropertyId)
      setActiveProperty(property ?? properties[0])
    }
  }, [properties])
  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <div className="flex overflow-hidden aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                <img className="object-cover w-full h-full" src={activeProperty?.pictureCover} />
              </div>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">
                  {activeProperty?.name}
                </span>
                <span className="truncate text-xs">{activeProperty?.contact}</span>
              </div>
              <ChevronsUpDown className="ml-auto" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            align="start"
            side={isMobile ? "bottom" : "right"}
            sideOffset={4}
          >
            <DropdownMenuLabel className="text-xs text-zinc-500 dark:text-zinc-400">
              Properties
            </DropdownMenuLabel>
            {properties?.map((property, index) => (
              <DropdownMenuItem
                key={property.name}
                onClick={() => {
                  setActiveProperty(property)
                  localStorage.setItem("property.active", property.id)
                }}
                className={`gap-2 p-2 ${activeProperty?.id === property.id ? "bg-green-200" : ""}`}
              >
                <div className={`flex size-6 items-center justify-center rounded-sm border`}>
                  <img className="object-cover w-full h-full" src={activeProperty?.pictureCover} />
                </div>
                {property.name}
                <DropdownMenuShortcut>⌘{index + 1}</DropdownMenuShortcut>
              </DropdownMenuItem>
            ))}
            <DropdownMenuSeparator />
            <DropdownMenuItem className="gap-2 p-2">
              <div className="flex size-6 items-center justify-center rounded-md border border-zinc-200 bg-white dark:border-zinc-800 dark:bg-zinc-950">
                <Plus className="size-4" />
              </div>
              <div className="font-medium text-zinc-500 dark:text-zinc-400">Add property</div>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  )
}
