import { NavItem } from '@/types/ui/nav-item'
import {
  BlindsIcon,
  Frame,
  HandCoins,
  HouseIcon,
  LayoutDashboard,
  Map,
  PieChart,
  Receipt,
  UserCheck,
  Users
} from 'lucide-react'

const navMain: NavItem[] = [
  {
    title: 'sidebar.dashboard',
    url: '/dashboard',
    icon: LayoutDashboard,
    isActive: true
  },
  {
    title: 'sidebar.rooms',
    icon: BlindsIcon,
    url: '/room',
    items: [
      {
        title: 'sidebar.addRoom',
        url: '/room/add',
      },
      {
        title: 'sidebar.roomList',
        url: '/room/list',
      },
    ]
  },
  {
    title: 'sidebar.tenants',
    icon: Users,
    url: '/tenant',
    items: [
      {
        title: 'sidebar.addTenant',
        url: '/tenant/add',
      },
      {
        title: 'sidebar.tenantList',
        url: '/tenant/list',
      },
    ]
  },
  {
    title: 'sidebar.assignments',
    icon: UserCheck,
    url: '/assignment',
    items: [
      {
        title: 'sidebar.addAssignment',
        url: '/assignment/add',
      },
      {
        title: 'sidebar.assignmentList',
        url: '/assignment/list',
      },
    ]
  },
  {
    title: 'sidebar.invoices',
    icon: Receipt,
    url: '/invoice',
    items: [
      {
        title: 'sidebar.addInvoice',
        url: '/invoice/add',
        // icon: PlusIcon,
      },
      {
        title: 'sidebar.invoiceList',
        url: '/invoice/list',
      },
    ]
  },
  {
    title: 'sidebar.payments',
    icon: HandCoins,
    url: '/payment',
    items: [
      {
        title: 'sidebar.addPayment',
        url: '/payment/add',
        // icon: PlusIcon,
      },
      {
        title: 'sidebar.paymentList',
        url: '/payment/list',
      },
    ]
  },
  {
    title: 'sidebar.properties',
    icon: HouseIcon,
    url: '/property',
    items: [
      {
        title: 'sidebar.addProperty',
        url: '/property/add',
        // icon: PlusIcon,
      },
      {
        title: 'sidebar.propertyList',
        url: '/property/list',
      },
    ]
  },
]
const projects: NavItem[] = [
  {
    title: 'Design Engineering',
    url: '#',
    icon: Frame
  },
  {
    title: 'Sales & Marketing',
    url: '#',
    icon: PieChart
  },
  {
    title: 'Travel',
    url: '#',
    icon: Map
  }
]

export const sidelink = {
  navMain,
  projects
}
