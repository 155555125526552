import { memo } from "react"
import { useTranslations } from "use-intl"
import Loading from '@/assets/loading.svg?react'
import WWWLoading from '@/assets/www-loading.svg?react'

export const LoadingScreen = memo(() => {
  const i18n = useTranslations()
  return (
    <div className="relative flex items-center justify-center w-full h-full">
      <div className='w-screen h-screen flex justify-center items-center'>
        <div className="flex-row justify-center items-center text-sm text-gray-700">
          <Loading className='w-32 h-32' />
          <div className='text-xl text-center dark:text-gray-200'>{i18n('ui.loading')}</div>
        </div>
      </div>
    </div>
  )
})

export const WWWLoadingScreen = memo(() => {
  const i18n = useTranslations()
  return (
    <div className="relative flex items-center justify-center w-full h-full">
      <div className='w-screen h-screen flex justify-center items-center'>
        <div className="flex-row justify-center items-center text-sm text-gray-700">
          <WWWLoading className='w-32 h-32 text-[#4AAE50]' />
          <div className='text-xl text-center dark:text-gray-200'>{i18n('ui.loading')}</div>
        </div>
      </div>
    </div>
  )
})