import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PropertyService } from "../service/property-service";
import { CreatePropertyRequest } from "../types/request/create-property";

const propertyService = new PropertyService();

export function useProperty() {
    const queryClient = useQueryClient();

    const {data: propertyList, isLoading: propertyListLoading} = useQuery({
        queryKey: propertyKeys.property,
        queryFn: async () => {
            console.log('fetching properties')
            const response = await propertyService.getProperties();
            if (response?.payload) {
                return response.payload;
            } else {
                throw new Error(response?.errors);
            }
        },
        staleTime: 1000 * 60 * 4,
        cacheTime: 1000 * 60 * 2,
    })

    const createPropertyMutation = useMutation({
        mutationFn: async (request: CreatePropertyRequest) => {
            const response = await propertyService.createProperty(request);
            if (response?.payload) {
                return response.payload;
            } else {
                throw new Error(response?.errors);
            }
        },
        onSuccess: (response) => {
            console.log(response)
            queryClient.invalidateQueries(propertyKeys.property);
        },
    });

    return {
        propertyList,
        propertyListLoading,
        createProperty: createPropertyMutation.mutateAsync,
        createPropertyLoading: createPropertyMutation.isLoading,
    }
}

const propertyKeys = {
    property: ["property"] as const,
} as const;