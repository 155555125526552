/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as LayoutImport } from './routes/_layout'
import { Route as AuthImport } from './routes/_auth'
import { Route as IndexImport } from './routes/index'
import { Route as Layout404Import } from './routes/_layout/404'
import { Route as LayoutAuthRoomIndexImport } from './routes/_layout/_auth/room/index'
import { Route as LayoutAuthPropertyIndexImport } from './routes/_layout/_auth/property/index'

// Create Virtual Routes

const LayoutAuthDashboardLazyImport = createFileRoute(
  '/_layout/_auth/dashboard',
)()
const LayoutAuthRoomListLazyImport = createFileRoute(
  '/_layout/_auth/room/list',
)()
const LayoutAuthRoomAddLazyImport = createFileRoute('/_layout/_auth/room/add')()
const LayoutAuthPropertyListLazyImport = createFileRoute(
  '/_layout/_auth/property/list',
)()
const LayoutAuthPropertyAddLazyImport = createFileRoute(
  '/_layout/_auth/property/add',
)()

// Create/Update Routes

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const LayoutRoute = LayoutImport.update({
  id: '/_layout',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const Layout404Route = Layout404Import.update({
  id: '/404',
  path: '/404',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutAuthDashboardLazyRoute = LayoutAuthDashboardLazyImport.update({
  id: '/_auth/dashboard',
  path: '/dashboard',
  getParentRoute: () => LayoutRoute,
} as any).lazy(() =>
  import('./routes/_layout/_auth/dashboard.lazy').then((d) => d.Route),
)

const LayoutAuthRoomIndexRoute = LayoutAuthRoomIndexImport.update({
  id: '/_auth/room/',
  path: '/room/',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutAuthPropertyIndexRoute = LayoutAuthPropertyIndexImport.update({
  id: '/_auth/property/',
  path: '/property/',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutAuthRoomListLazyRoute = LayoutAuthRoomListLazyImport.update({
  id: '/_auth/room/list',
  path: '/room/list',
  getParentRoute: () => LayoutRoute,
} as any).lazy(() =>
  import('./routes/_layout/_auth/room/list.lazy').then((d) => d.Route),
)

const LayoutAuthRoomAddLazyRoute = LayoutAuthRoomAddLazyImport.update({
  id: '/_auth/room/add',
  path: '/room/add',
  getParentRoute: () => LayoutRoute,
} as any).lazy(() =>
  import('./routes/_layout/_auth/room/add.lazy').then((d) => d.Route),
)

const LayoutAuthPropertyListLazyRoute = LayoutAuthPropertyListLazyImport.update(
  {
    id: '/_auth/property/list',
    path: '/property/list',
    getParentRoute: () => LayoutRoute,
  } as any,
).lazy(() =>
  import('./routes/_layout/_auth/property/list.lazy').then((d) => d.Route),
)

const LayoutAuthPropertyAddLazyRoute = LayoutAuthPropertyAddLazyImport.update({
  id: '/_auth/property/add',
  path: '/property/add',
  getParentRoute: () => LayoutRoute,
} as any).lazy(() =>
  import('./routes/_layout/_auth/property/add.lazy').then((d) => d.Route),
)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_layout': {
      id: '/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LayoutImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/_layout/404': {
      id: '/_layout/404'
      path: '/404'
      fullPath: '/404'
      preLoaderRoute: typeof Layout404Import
      parentRoute: typeof LayoutImport
    }
    '/_layout/_auth/dashboard': {
      id: '/_layout/_auth/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof LayoutAuthDashboardLazyImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/_auth/property/add': {
      id: '/_layout/_auth/property/add'
      path: '/property/add'
      fullPath: '/property/add'
      preLoaderRoute: typeof LayoutAuthPropertyAddLazyImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/_auth/property/list': {
      id: '/_layout/_auth/property/list'
      path: '/property/list'
      fullPath: '/property/list'
      preLoaderRoute: typeof LayoutAuthPropertyListLazyImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/_auth/room/add': {
      id: '/_layout/_auth/room/add'
      path: '/room/add'
      fullPath: '/room/add'
      preLoaderRoute: typeof LayoutAuthRoomAddLazyImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/_auth/room/list': {
      id: '/_layout/_auth/room/list'
      path: '/room/list'
      fullPath: '/room/list'
      preLoaderRoute: typeof LayoutAuthRoomListLazyImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/_auth/property/': {
      id: '/_layout/_auth/property/'
      path: '/property'
      fullPath: '/property'
      preLoaderRoute: typeof LayoutAuthPropertyIndexImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/_auth/room/': {
      id: '/_layout/_auth/room/'
      path: '/room'
      fullPath: '/room'
      preLoaderRoute: typeof LayoutAuthRoomIndexImport
      parentRoute: typeof LayoutImport
    }
  }
}

// Create and export the route tree

interface LayoutRouteChildren {
  Layout404Route: typeof Layout404Route
  LayoutAuthDashboardLazyRoute: typeof LayoutAuthDashboardLazyRoute
  LayoutAuthPropertyAddLazyRoute: typeof LayoutAuthPropertyAddLazyRoute
  LayoutAuthPropertyListLazyRoute: typeof LayoutAuthPropertyListLazyRoute
  LayoutAuthRoomAddLazyRoute: typeof LayoutAuthRoomAddLazyRoute
  LayoutAuthRoomListLazyRoute: typeof LayoutAuthRoomListLazyRoute
  LayoutAuthPropertyIndexRoute: typeof LayoutAuthPropertyIndexRoute
  LayoutAuthRoomIndexRoute: typeof LayoutAuthRoomIndexRoute
}

const LayoutRouteChildren: LayoutRouteChildren = {
  Layout404Route: Layout404Route,
  LayoutAuthDashboardLazyRoute: LayoutAuthDashboardLazyRoute,
  LayoutAuthPropertyAddLazyRoute: LayoutAuthPropertyAddLazyRoute,
  LayoutAuthPropertyListLazyRoute: LayoutAuthPropertyListLazyRoute,
  LayoutAuthRoomAddLazyRoute: LayoutAuthRoomAddLazyRoute,
  LayoutAuthRoomListLazyRoute: LayoutAuthRoomListLazyRoute,
  LayoutAuthPropertyIndexRoute: LayoutAuthPropertyIndexRoute,
  LayoutAuthRoomIndexRoute: LayoutAuthRoomIndexRoute,
}

const LayoutRouteWithChildren =
  LayoutRoute._addFileChildren(LayoutRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof LayoutRouteWithChildren
  '/login': typeof LoginRoute
  '/404': typeof Layout404Route
  '/dashboard': typeof LayoutAuthDashboardLazyRoute
  '/property/add': typeof LayoutAuthPropertyAddLazyRoute
  '/property/list': typeof LayoutAuthPropertyListLazyRoute
  '/room/add': typeof LayoutAuthRoomAddLazyRoute
  '/room/list': typeof LayoutAuthRoomListLazyRoute
  '/property': typeof LayoutAuthPropertyIndexRoute
  '/room': typeof LayoutAuthRoomIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof LayoutRouteWithChildren
  '/login': typeof LoginRoute
  '/404': typeof Layout404Route
  '/dashboard': typeof LayoutAuthDashboardLazyRoute
  '/property/add': typeof LayoutAuthPropertyAddLazyRoute
  '/property/list': typeof LayoutAuthPropertyListLazyRoute
  '/room/add': typeof LayoutAuthRoomAddLazyRoute
  '/room/list': typeof LayoutAuthRoomListLazyRoute
  '/property': typeof LayoutAuthPropertyIndexRoute
  '/room': typeof LayoutAuthRoomIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_auth': typeof AuthRoute
  '/_layout': typeof LayoutRouteWithChildren
  '/login': typeof LoginRoute
  '/_layout/404': typeof Layout404Route
  '/_layout/_auth/dashboard': typeof LayoutAuthDashboardLazyRoute
  '/_layout/_auth/property/add': typeof LayoutAuthPropertyAddLazyRoute
  '/_layout/_auth/property/list': typeof LayoutAuthPropertyListLazyRoute
  '/_layout/_auth/room/add': typeof LayoutAuthRoomAddLazyRoute
  '/_layout/_auth/room/list': typeof LayoutAuthRoomListLazyRoute
  '/_layout/_auth/property/': typeof LayoutAuthPropertyIndexRoute
  '/_layout/_auth/room/': typeof LayoutAuthRoomIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/login'
    | '/404'
    | '/dashboard'
    | '/property/add'
    | '/property/list'
    | '/room/add'
    | '/room/list'
    | '/property'
    | '/room'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/login'
    | '/404'
    | '/dashboard'
    | '/property/add'
    | '/property/list'
    | '/room/add'
    | '/room/list'
    | '/property'
    | '/room'
  id:
    | '__root__'
    | '/'
    | '/_auth'
    | '/_layout'
    | '/login'
    | '/_layout/404'
    | '/_layout/_auth/dashboard'
    | '/_layout/_auth/property/add'
    | '/_layout/_auth/property/list'
    | '/_layout/_auth/room/add'
    | '/_layout/_auth/room/list'
    | '/_layout/_auth/property/'
    | '/_layout/_auth/room/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthRoute: typeof AuthRoute
  LayoutRoute: typeof LayoutRouteWithChildren
  LoginRoute: typeof LoginRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthRoute: AuthRoute,
  LayoutRoute: LayoutRouteWithChildren,
  LoginRoute: LoginRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_auth",
        "/_layout",
        "/login"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_auth": {
      "filePath": "_auth.tsx"
    },
    "/_layout": {
      "filePath": "_layout.tsx",
      "children": [
        "/_layout/404",
        "/_layout/_auth/dashboard",
        "/_layout/_auth/property/add",
        "/_layout/_auth/property/list",
        "/_layout/_auth/room/add",
        "/_layout/_auth/room/list",
        "/_layout/_auth/property/",
        "/_layout/_auth/room/"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/_layout/404": {
      "filePath": "_layout/404.tsx",
      "parent": "/_layout"
    },
    "/_layout/_auth/dashboard": {
      "filePath": "_layout/_auth/dashboard.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/_auth/property/add": {
      "filePath": "_layout/_auth/property/add.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/_auth/property/list": {
      "filePath": "_layout/_auth/property/list.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/_auth/room/add": {
      "filePath": "_layout/_auth/room/add.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/_auth/room/list": {
      "filePath": "_layout/_auth/room/list.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/_auth/property/": {
      "filePath": "_layout/_auth/property/index.tsx",
      "parent": "/_layout"
    },
    "/_layout/_auth/room/": {
      "filePath": "_layout/_auth/room/index.tsx",
      "parent": "/_layout"
    }
  }
}
ROUTE_MANIFEST_END */
