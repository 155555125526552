import * as React from 'react'
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: false,
                refetchOnWindowFocus: false
            },
            mutations: {
                retry: 1,
                retryDelay: 1000
            }
        }
    })

    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    )
}