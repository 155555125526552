import { ChevronRight } from 'lucide-react'

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/components/ui/collapsible'
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem
} from '@/components/ui/sidebar'
import { NavLink } from '../shared/link/navlink'
import { NavItem } from '@/types/ui/nav-item'
import { useTranslations } from 'use-intl'
import { usePathname } from '@/hooks/use-pathname'

export function NavMain({ items }: { items: NavItem[] }) {
  const pathname = usePathname()
  const i18n = useTranslations()
  const header = (item: NavItem) => {
    const hasSubItems = item.items != null && item.items.length > 0
    return hasSubItems ? (
      <div className='cursor-pointer'>
        {item.icon && <item.icon />}
        <span>{i18n(item.title)}</span>
        <ChevronRight className='ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90' />
      </div>
    ) : (
      <NavLink to={item?.url as '.'}>
        {item.icon && <item.icon />}
        <span>{i18n(item.title)}</span>
      </NavLink>
    )
  }
  return (
    <SidebarGroup>
      <SidebarGroupLabel>Platform</SidebarGroupLabel>
      <SidebarMenu>
        {items.map(item => {
          const hasSubItems = item.items != null && item.items.length > 0
          item.isActive = pathname.startsWith(item.url as string)
          return (
            <Collapsible
              key={item.title}
              asChild
              defaultOpen={item.isActive}
              className='group/collapsible'
            >
              <SidebarMenuItem>
                <CollapsibleTrigger asChild>
                  <SidebarMenuButton asChild tooltip={item.title}>
                    {header(item)}
                  </SidebarMenuButton>
                </CollapsibleTrigger>
                {hasSubItems && (
                  <CollapsibleContent>
                    <SidebarMenuSub>
                      {item.items?.map(subItem => (
                        <SidebarMenuSubItem key={subItem.title}>
                          <SidebarMenuSubButton asChild>
                            <NavLink to={subItem.url as '..'}>
                              {subItem.icon && <subItem.icon />}
                              <span>{i18n(subItem.title)}</span>
                            </NavLink>
                          </SidebarMenuSubButton>
                        </SidebarMenuSubItem>
                      ))}
                    </SidebarMenuSub>
                  </CollapsibleContent>
                )}
              </SidebarMenuItem>
            </Collapsible>
          )
        })}
      </SidebarMenu>
    </SidebarGroup>
  )
}
