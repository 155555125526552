import axios from 'axios';
import { API_CONFIG } from './config';
import { AUTH_KEYS } from '@/constants/auth-keys';

export const axiosInstance = axios.create(API_CONFIG);

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(AUTH_KEYS.ACCESS_TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            // Handle unauthorized access
            localStorage.removeItem(AUTH_KEYS.ACCESS_TOKEN);
            window.location.href = '/login';
        } else if(error.response?.status === 403) {
            return error.response;
        } else if(error.response?.status === 400) {
            return error.response;
        }
        return Promise.reject(error);
    }
);