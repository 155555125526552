import Layout from '@/pages/layout'
import { createFileRoute, Outlet } from '@tanstack/react-router'
import NextTopLoader from 'nextjs-toploader'
import { memo, Suspense } from 'react'

const MainScreen = memo(() => {
    return (
        <Layout>
            <Suspense fallback={<div>Loading...</div>}>
                <NextTopLoader color='#4BAE50' showSpinner={false} speed={300} crawlSpeed={300} />
                <Outlet />
            </Suspense>
        </Layout>
    )
})

export const Route = createFileRoute('/_layout')({
    component: MainScreen,
})
