import { AUTH_KEYS } from "@/constants/auth-keys"
import { AuthService } from "@/services/auth"
import { ApiResponse } from "@/types/api/api-response"
import { LoginRequest } from "@/types/api/request/login"
import { Auth } from "@/types/api/user/auth"
import { AuthError } from "@/types/api/user/auth-error"
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query"
import React from "react"

const authService = new AuthService()

export function useAuth() {
    const queryClient = useQueryClient()

    // Enhanced user query with automatic token refresh
    const { data: authState } = useQuery({
        queryKey: authKeys.user,
        queryFn: storage.getAuthState,
        initialData: storage.getAuthState,
        staleTime: Infinity,
    })

    // Refresh token mutation
    const refreshMutation = useMutation({
        mutationFn: async () => {
            const refreshToken = storage.getAuthState().refreshToken
            if (!refreshToken) {
                throw new AuthenticationError(
                    'No refresh token available',
                    'NO_REFRESH_TOKEN'
                )
            }
            try {
                const response = await authService.refreshToken(refreshToken)
                if (!response.success) {
                    throw new AuthenticationError(
                        'Failed to refresh token',
                        'REFRESH_FAILED',
                        response.statusCode,
                    )
                }
                return response
            } catch (error) {
                // Force logout on refresh failure
                storage.clearAuthState()
                queryClient.setQueryData(authKeys.user, null)
                throw error
            }
        },
        onSuccess: (response: ApiResponse<Auth>) => {
            const expiredAt = response.payload.expiredAt ? new Date(response.payload.expiredAt) : null
            const newAuthState: Auth = {
                accessToken: response.payload.accessToken,
                refreshToken: response.payload.refreshToken,
                username: response.payload.username,
                expiredAt: expiredAt
            }
            storage.setAuthState(newAuthState)
            queryClient.setQueryData(authKeys.user, newAuthState)
        }
    })

    // Enhanced login mutation with error handling
    const loginMutation = useMutation({
        mutationFn: async (loginRequest: LoginRequest) => {
            try {
                const response = await authService.login(loginRequest)
                if (!response.success) {
                    throw new AuthenticationError(
                        'Login failed',
                        'LOGIN_FAILED',
                        response.statusCode,
                        response.payload
                    )
                }
                return response
            } catch (error) {
                if (error instanceof AuthenticationError) {
                    throw error
                }
                throw new AuthenticationError(
                    'Login failed',
                    'UNKNOWN_ERROR',
                    500,
                    error
                )
            }
        },
        onSuccess: (response) => {
            const expiredAt = response.payload.expiredAt
            const newAuthState: Auth = {
                accessToken: response.payload.accessToken,
                refreshToken: response.payload.refreshToken,
                username: response.payload.username,
                expiredAt: expiredAt ? new Date(expiredAt) : null
            }
            storage.setAuthState(newAuthState)
            queryClient.setQueryData(authKeys.user, newAuthState)
        },
        onError: () => {
            storage.setAuthState({
                accessToken: 'access',
                refreshToken: 'refresh',
                username: 'user',
                expiredAt: new Date()
            })
        }
    })

    // Enhanced logout mutation
    const logoutMutation = useMutation({
        mutationFn: async () => {
            const currentAuthState = storage.getAuthState()
            if (currentAuthState.accessToken) {
                try {
                    await authService.logout(currentAuthState.accessToken)
                } catch (error) {
                    console.error('Logout request failed:', error)
                    // Continue with local logout even if server request fails
                }
            }
            storage.clearAuthState()
            return Promise.resolve()
        },
        onSuccess: () => {
            queryClient.setQueryData(authKeys.user, null)
        }
    })

    const currentUser = useQuery({
        queryKey: ['auth', 'currentUser'],
        queryFn: async () => {
            const response = await authService.getCurrentUser()
            if (!response.success) {
                throw new AuthenticationError(
                    'Failed to get current user',
                    'GET_CURRENT_USER_FAILED',
                    response.statusCode,
                    response.payload
                )
            }
            return response.payload
        },
        enabled: !!authState?.accessToken,
    })

    // Auto refresh token when nearing expiration
    React.useEffect(() => {
        if (!authState?.expiredAt) return

        const timeUntilExpiry = new Date(authState.expiredAt).getTime() - Date.now()
        const refreshThreshold = 5 * 60 * 1000 // 5 minutes

        if (timeUntilExpiry > 0 && timeUntilExpiry < refreshThreshold) {
            refreshMutation.mutate()
        }
    }, [refreshMutation, authState?.expiredAt])

    return {
        isAuthenticated: !!authState?.accessToken,
        user: currentUser.data,
        login: loginMutation.mutateAsync,
        logout: logoutMutation.mutateAsync,
        refresh: refreshMutation.mutateAsync,
        isLoggingIn: loginMutation.isLoading,
        isLoggingOut: logoutMutation.isLoading,
        isRefreshing: refreshMutation.isLoading,
        loginError: loginMutation.error as AuthError | null,
        refreshError: refreshMutation.error as AuthError | null,
    }
}

class AuthenticationError extends Error {
    constructor(
        message: string,
        public code: string,
        public status?: number,
        public data?: unknown
    ) {
        super(message)
        this.name = 'AuthenticationError'
    }
}

// Query keys with type safety
const authKeys = {
    user: ['auth', 'user'] as const,
    login: ['auth', 'login'] as const,
    logout: ['auth', 'logout'] as const,
    refresh: ['auth', 'refresh'] as const,
} as const

// Enhanced storage utilities with type safety
const storage = {
    getAuthState(): Auth {
        const expiredAt = localStorage.getItem(AUTH_KEYS.EXPIRED_AT)
        return {
            accessToken: localStorage.getItem(AUTH_KEYS.ACCESS_TOKEN),
            refreshToken: localStorage.getItem(AUTH_KEYS.REFRESH_TOKEN),
            username: localStorage.getItem(AUTH_KEYS.USERNAME),
            expiredAt: expiredAt ? new Date(expiredAt) : null
        }
    },

    setAuthState(state: Partial<Auth>): void {
        if (state.accessToken) {
            localStorage.setItem(AUTH_KEYS.ACCESS_TOKEN, state.accessToken)
        }
        if (state.refreshToken) {
            localStorage.setItem(AUTH_KEYS.REFRESH_TOKEN, state.refreshToken)
        }
        if (state.username) {
            localStorage.setItem(AUTH_KEYS.USERNAME, state.username.toString())
        }
        if (state.expiredAt) {
            localStorage.setItem(AUTH_KEYS.EXPIRED_AT, state.expiredAt.toISOString())
        }
    },

    clearAuthState(): void {
        localStorage.removeItem(AUTH_KEYS.ACCESS_TOKEN)
        localStorage.removeItem(AUTH_KEYS.REFRESH_TOKEN)
        localStorage.removeItem(AUTH_KEYS.USERNAME)
        localStorage.removeItem(AUTH_KEYS.EXPIRED_AT)
    }
}
