import { SidebarInset, SidebarTrigger } from '@/components/ui/sidebar';
import { Separator } from '@/components/ui/separator';
import { AppSidebar } from '@/components/sidebar/app-sidebar';
import { NavbarActions } from '@/components/navbar/navbar-actions';
import { NavbarBreadcrumb } from '@/components/navbar/breadcrumb';
import { Suspense, useEffect, useState } from 'react';
import { useAuth } from '@/api/queries/useAuthQuery';
import Login from '@/pages/auth/login';

export default function Layout({ children }: { children: React.ReactNode }) {
  const { isAuthenticated, user } = useAuth();
  const [isAuthenticatedState, setIsAuthenticatedState] = useState(true);

  useEffect(() => {
    setIsAuthenticatedState(isAuthenticated && !!user);
  }, [isAuthenticated, user]);

  if (!isAuthenticatedState) {
    return <Login />;
  }

  return (
    <div className='flex w-full h-screen overflow-hidden bg-secondary'>
      <AppSidebar />
      <main className='flex w-0 flex-1 flex-col overflow-hidden'>
        <SidebarInset>
          <header className='sticky z-50 top-0 flex justify-between h-16 shrink-0 items-center gap-2 border-b bg-background backdrop-blur-xl transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12'>
            <div className='flex flex-1 items-center gap-1.5 px-2'>
              <SidebarTrigger />
              <Separator orientation='vertical' className='mr-2 h-4' />
              <NavbarBreadcrumb />
            </div>
            <div className='ml-auto px-3'>
              <NavbarActions />
            </div>
          </header>
          <div className='gap-4 px-6 py-4 h-screen overflow-x-hidden overflow-y-auto flex-1 relative'>
            <Suspense fallback={<div>Loading...</div>}>
              {children}
            </Suspense>
          </div>
        </SidebarInset>
      </main>
    </div>
  );
}
