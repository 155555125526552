import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from '@/components/ui/breadcrumb'
import { useBreadcrumbs } from '@/hooks/use-breadcrumb'
import { Link } from '@tanstack/react-router'
import { Fragment } from 'react'

export function NavbarBreadcrumb() {
  const items = useBreadcrumbs();
  if (items.length === 0) return null;
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <Fragment>
          <BreadcrumbItem>
            <BreadcrumbLink asChild={true}>
              <Link to={'/dashboard'}>{'Dashboard'}</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          {items.length > 1 && (
            <BreadcrumbSeparator className='hidden md:block' />
          )}
        </Fragment>
        {items.map((item, index) => {
          if (item?.title === 'Dashboard') return null;
          return (
            <Fragment key={item.title}>
              {index !== items.length - 1 && (
                <BreadcrumbItem>
                  <BreadcrumbLink asChild={true}>
                    <Link to={item?.link}>{item?.title}</Link>
                  </BreadcrumbLink>
                </BreadcrumbItem>
              )}
              {index < items.length - 1 && (
                <BreadcrumbSeparator className='hidden md:block' />
              )}
              {index === items.length - 1 && (
                <BreadcrumbPage>{item.title}</BreadcrumbPage>
              )}
            </Fragment>
          )
        })}
      </BreadcrumbList>
    </Breadcrumb>
  )
}
