import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'

interface AuthContext {
  isAuthenticated: boolean
}

export const Route = createRootRouteWithContext<AuthContext>()({
  component: () => {
    return (
      <>
        <Outlet />
        {import.meta.env.VITE_NODE_ENV === 'dev' && <TanStackRouterDevtools position='bottom-right' />}
      </>
    )
  },
})
