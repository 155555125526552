import { Button } from '@/components/ui/button'
import { useRouter } from '@/hooks/use-router'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Suspense } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { HelmetProvider } from 'react-helmet-async'
import { ThemeProvider } from '@/providers/theme-provider'
import { SidebarProvider } from '@/components/ui/sidebar'
import { LanguageProvider } from '@/providers/language-provider'
import { Toaster } from 'sonner'

const ErrorFallback = ({ error }: FallbackProps) => {
  const router = useRouter()
  console.log('error', error)
  return (
    <div
      className='flex h-screen w-screen flex-col items-center  justify-center text-red-500'
      role='alert'
    >
      <h2 className='text-2xl font-semibold'>
        Ooops, something went wrong :({' '}
      </h2>
      <pre className='text-2xl font-bold'>{error.message}</pre>
      <pre>{error.stack}</pre>
      <Button className='mt-4' onClick={() => router.back()}>
        Go back
      </Button>
    </div>
  )
}

export default function AppProvider({
  children
}: {
  children: React.ReactNode
}) {
  return (
    <Suspense>
      <HelmetProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {import.meta.env.VITE_NODE_ENV === 'dev' && <ReactQueryDevtools />}
          <Toaster />
          <ThemeProvider defaultTheme={'light'} storageKey='vite-ui-theme'>
            <LanguageProvider
              defaultLanguage='en'
              storageKey='language'
            >
              <SidebarProvider>{children}</SidebarProvider>
            </LanguageProvider>
          </ThemeProvider>
        </ErrorBoundary>
      </HelmetProvider>
    </Suspense>
  )
}
