import { ApiService } from "@/services/api";
import { CreatePropertyRequest } from "../types/request/create-property";
import { PropertyResponse } from "../types/response/property-response";
import { ApiResponse } from "@/types/api/api-response";
import { API_ENDPOINTS } from "@/api/endpoints";

export class PropertyService extends ApiService {
    async getProperties(): Promise<ApiResponse<PropertyResponse[]>> {
        return this.get<ApiResponse<PropertyResponse[]>>(API_ENDPOINTS.property.getAll + "?page=0&size=100");
    }
    async isPropertyExistedByPropertyNameAndUserId(name: string): Promise<boolean> {
        return this.get<boolean>(API_ENDPOINTS.property.existByName + `?name=${name}`);
    }
    async createProperty(request: CreatePropertyRequest): Promise<ApiResponse<PropertyResponse>> {
        return this.post<ApiResponse<PropertyResponse>, CreatePropertyRequest>(API_ENDPOINTS.property.create, request);
    }
}