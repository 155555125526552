import { Auth } from "@/types/api/user/auth";
import { ApiService } from "./api";
import { ApiResponse } from "@/types/api/api-response";
import { LoginRequest } from "@/types/api/request/login";
import { API_ENDPOINTS } from "@/api/endpoints";
import axios from "axios";
import { User } from "@/types/api/user/user";

export class AuthService extends ApiService {
    async getCurrentUser(): Promise<ApiResponse<User>> {
        return this.get<ApiResponse<User>>(API_ENDPOINTS.auth.currentUser);
    }
    async login(login: LoginRequest): Promise<ApiResponse<Auth>> {
        const res = this.post<ApiResponse<Auth>, LoginRequest>(API_ENDPOINTS.auth.login, login);
        res.then((res) => console.log(res))
        return res;
    }
    async logout(accessToken: string): Promise<void> {
        console.log(accessToken)
        throw new Error('Method not implemented.');
    }
    async refreshToken(refreshToken: string): Promise<ApiResponse<Auth>> {
        return axios.post(API_ENDPOINTS.auth.refresh, {
            headers: {
                Authorization: `Bearer ${refreshToken}`
            }
        });
    }
}