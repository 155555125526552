import AppProvider from './providers/app-provider'
import { RouterProvider, createRouter } from '@tanstack/react-router'

// Import the generated route tree
import { routeTree } from './routeTree.gen'
import NotFound from './pages/errors/not-found'
import { AuthProvider } from './providers/auth-provider'
import { useAuth } from './api/queries/useAuthQuery'
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from "@vercel/analytics/react"
import { LoadingScreen } from './components/shared/page/loading-screen'

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPendingComponent: LoadingScreen,
  defaultNotFoundComponent: NotFound,
  defaultPreload: 'intent',
  defaultPreloadDelay: 500,
  context: {
    isAuthenticated: undefined!,
  },
})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

function GuardedRoute() {
  const isAuthenticated = useAuth().isAuthenticated
  return (
    <RouterProvider router={router} context={{ isAuthenticated }} />
  )
}

function App() {
  return (
    <AuthProvider>
      <SpeedInsights />
      <Analytics />
      <AppProvider>
        <GuardedRoute />
      </AppProvider>
    </AuthProvider>
  )
}

export default App
