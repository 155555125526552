export const API_ENDPOINTS = {
    auth: {
        login: '/auth/login',
        register: '/auth/register',
        logout: '/auth/logout',
        refresh: '/auth/refresh',
        currentUser: '/auth/user/me',
    },
    room: {
        getAll: '/room/all',
        create: '/room/create',
    },
    property: {
        getAll: '/property/all',
        create: '/property/create',
        existByName: '/property/exist-by-name',
        dashboard: '/property/dashboard',
    },
    file: {
        upload: '/file/upload',
    }
} as const;