import * as React from 'react'

import { NavMain } from '@/components/sidebar/nav-main'
import { NavProjects } from '@/components/sidebar/nav-projects'
import { NavUser } from '@/components/sidebar/nav-user'
import { PropertySwitcher } from '@/components/sidebar/property-switcher'
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail
} from '@/components/ui/sidebar'
import { GalleryVerticalEnd } from 'lucide-react'
import { sidelink } from '@/constants/sidebars'
import { useProperty } from '@/pages/property/queries/useProperty'

const data = {
  user: {
    name: 'shadcn',
    email: 'm@example.com',
    avatar: '/avatars/shadcn.jpg'
  },
  properties: [
    {
      name: 'Acme Inc',
      pictureCover: GalleryVerticalEnd,
      plan: 'Enterprise'
    }
  ]
}
export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { propertyList } = useProperty()
  return (
    <Sidebar collapsible='icon' {...props}>
      <SidebarHeader>
        <PropertySwitcher properties={propertyList ?? []} />
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={sidelink.navMain} />
        <NavProjects projects={sidelink.projects} />
      </SidebarContent>
      <SidebarFooter>
        <NavUser user={data.user} />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  )
}
